function bookingForm (initial, recentSearchModule, mobileDatePicker) {
    var recentSearches = recentSearchModule.init({
        departDateInputId: '#departDate',
        returnDateInputId: '#returnDate'
    });
    var state = {
        val: $j.extend(true, {
                tripType: 'roundTrip',
                bookingType: 'revenueBooking',
                refundable: false,
                recentSearches: recentSearches,
                dateFormat: 'mm/dd/yyyy',
                cabinType: 'SHOW_ALL',
                passengerCount: 1
            }, initial),
        recentSearchModule: recentSearchModule || {
            init: function() {
                return [];
            }
        },
        mobileDatePicker: mobileDatePicker || function() { return {} },
        update: function (event) {
            state.val = transition(state.val, event.val);
            render(state, event);
        }
    };

    render(state, { val: 'init' });
    return state;
}

function transition (stateVal, eventVal) {
    switch (eventVal) {
        case 'oneway-click':
            stateVal.tripType = 'oneWay';
            return stateVal;

        case 'roundtrip-click':
            stateVal.tripType = 'roundTrip';
            return stateVal;

        case 'revenue-booking-click':
            stateVal.bookingType = 'revenueBooking';
            return stateVal;

        case 'award-booking-click':
            stateVal.bookingType = 'awardBooking';
            return stateVal;

        default:
            return stateVal;
    }
}

var _setToSession = function(isTripLink) {
    //biz rule, if user unchecks the triplink box, do not default to checked again
    // during the session
        $j.ajax({
            url: "/home/ajax/triplinkSelection",
            type: 'GET',
            contentType : 'application/json; charset=UTF-8',
            data: { 'tripLink' : isTripLink },
            dataType: 'JSON',
            async : true,
            cache : false,
            timeout: 5000
        });
};

function render (state, event) {
    switch (event.val) {
        case 'init':
            $j('[href="#roundtrip"]').click(state.update.bind(window, { val: 'roundtrip-click' }));
            $j('[href="#oneway"]').click(state.update.bind(window, { val: 'oneway-click' }));

            var awardBookingCheckbox = $j('#awardBooking2');
            var tripLinkCheckbox = $j('#tripLink');

            awardBookingCheckbox.change(function () {
                awardBookingCheckbox.val(awardBookingCheckbox.prop('checked'));
                if (tripLinkCheckbox.length) { tripLinkCheckbox.prop("disabled", this.checked); }
                return state.update({ val: awardBookingCheckbox.prop('checked') ? 'award-booking-click' : 'revenue-booking-click' });
            });

            tripLinkCheckbox.change(function () {
                tripLinkCheckbox.val(tripLinkCheckbox.prop('checked'));
                awardBookingCheckbox.prop("disabled", this.checked);
                initializePassengersDropdown();
                _setToSession(this.checked);
            });

            var recentSearchDropDown = $j('#recentSearchDropDown');

            const now = new Date();
            const $depart = $j('#departDate');
            const $return = $j('#returnDate');

            state.mobileDatePicker({ dateRange: { start: '#departDate', end: '#returnDate' } });

            if (state.val.recentSearches.length !== 0) {
                $j('#recentSearchDropDown').change(function () {
                    return state.update({val: 'recent-search-change', data: $j('#recentSearchDropDown').val()});
                });
            }

            $j('#refundable1').attr('checked', state.val.refundable);

            switch (state.val.tripType) {
                case 'roundTrip':
                    $j('#tripType').val('roundTrip');
                    $j('#departureDateSection')
                        .removeClass('span-phone12')
                        .addClass('span-phone6');
                    $j('[href="#roundtrip"]').parent('li').addClass('ui-tabs-active ui-state-active');
                    state.mobileDatePicker({ dateRange: { start: '#departDate', end: '#returnDate' } });
                    break;

                case 'oneWay':
                    $j('#tripType').val('oneWay');
                    $j('[data-behavior="#roundtrip"]').addClass('is-hidden');
                    $j('#departureDateSection')
                        .removeClass('span-phone6')
                        .addClass('span-phone12');
                    $j('[href="#oneway"]').parent('li').addClass('ui-tabs-active ui-state-active');
                    $j('#returnDate').val('');
                    state.mobileDatePicker({ dateRange: { start: '#departDate', end: '#returnDate' } });
                    break;
            }

            switch (state.val.bookingType) {
                case 'awardBooking':
                    $j('#awardCabin [value="' + state.val.cabinType + '"]').attr('selected', true);
                    $j('#awardBooking2').prop('checked', true);
                    if ($j('#tripLink').length) {
                    	$j('#tripLink').prop("disabled", true);
                	}
                    $j('.show-award-booking').show();
                    $j('.show-revenue-booking').hide();
                    break;

                case 'revenueBooking':
                    $j('#revenueCabin [value="' + state.val.cabinType + '"]').attr('selected', true);
                    $j('#awardBooking2').prop('checked', false);
                    if ($j('#tripLink').length && $j('#tripLink').prop('checked')) {
                        $j('#awardBooking2').prop("disabled", true);
                    }
                    $j('.show-revenue-booking').show();
                    $j('.show-award-booking').hide();
                    break;
            }
            break;

            case 'roundtrip-click':
                $j('#tripType').val('roundTrip');
                $j('[data-behavior="#roundtrip"]').removeClass('is-hidden');
                $j('#departureDateSection')
                    .removeClass('span-phone12')
                    .addClass('span-phone6');
                $j('[href="#oneway"]').parent('li').removeClass('ui-tabs-active ui-state-active');
                $j('[href="#roundtrip"]').parent('li').addClass('ui-tabs-active ui-state-active');
                state.mobileDatePicker({ dateRange: { start: '#departDate', end: '#returnDate' } });
                break;

            case 'oneway-click':
                $j('#tripType').val('oneWay');
                $j('[data-behavior="#roundtrip"]').addClass('is-hidden');
                $j('#departureDateSection')
                    .removeClass('span-phone6')
                    .addClass('span-phone12');
                $j('[href="#roundtrip"]').parent('li').removeClass('ui-tabs-active ui-state-active');
                $j('[href="#oneway"]').parent('li').addClass('ui-tabs-active ui-state-active');
                $j('#returnDate').val('');
                state.mobileDatePicker({ dateRange: { start: '#departDate', end: '#returnDate' } });
                break;

            case 'revenue-booking-click':
                var awardBookingCheckbox = $j('#awardBooking2');
                var tripLinkCheckbox = $j('#tripLink');

            	tripLinkCheckbox.prop('disabled', false);
            	awardBookingCheckbox.prop('checked', false);
            	awardBookingCheckbox.prop('disabled', false);

                $j('.show-revenue-booking').show();
                $j('.show-award-booking').hide();
                break;

            case 'award-booking-click':
                var awardBookingCheckbox = $j('#awardBooking2');
                var tripLinkCheckbox = $j('#tripLink');

            	tripLinkCheckbox.prop('disabled', true);
            	tripLinkCheckbox.prop('checked', false);
            	awardBookingCheckbox.prop('disabled', false);

            	$j('.show-revenue-booking').hide();
                $j('.show-award-booking').show();
                break;

            case 'recent-search-change':
                switch (event.data) {
                    case 'waterMark':
                        return;

                    case 'xxx':
                        deleteRecentSearchesFromLS();
                        return $j('#recentSearchSection').remove();

                    default:
                        var recentTripType = state.recentSearchModule.get(event.data, 'tripType');
                        var recentWasAwardBooking = state.recentSearchModule.get(event.data, 'award');

                        state.update({ val: recentTripType.toLowerCase().concat('-click') });
                        state.update({ val: recentWasAwardBooking ? 'award-booking-click' : 'revenue-booking-click' });

                        state.recentSearchModule.set(event.data);

                        var $departDate = $j('#departDate');
                        var $arrivalDate = $j('#returnDate');
                        var departDate = new Date($departDate.val());
                        var arrivalDate = new Date($arrivalDate.val());
                        $departDate.val($j.datepicker.formatDate(state.val.dateFormat, departDate));
                        // Using isNaN is necessary to handle invalid dates for format dates.
                        $arrivalDate.val($j.datepicker.formatDate(state.val.dateFormat, isNaN(arrivalDate) ? "" : arrivalDate));
                        state.mobileDatePicker({ dateRange: { start: '#departDate', end: '#returnDate' } });
                }
    }
}

var initializePassengersDropdown = function (submitting) {
    var isTripLink = $j('#tripLink').prop('checked');
    var passengerDropdown = $j('#passengerCount');

    if (submitting) {
        passengerDropdown.prop('disabled', false);
        } else if(isTripLink) {
            $j('#passengerCount option[value="1"]').prop('selected', true);
            passengerDropdown.prop('disabled', true);
    } else {
        passengerDropdown.prop('disabled', false);
    }
}
